<template>
    <SwiftFlexAdvert />
    <PageSection isLastSection="true">
        <div class="row action">
            <PageSectionHeading v-if="preferences.showActionDraftOrders || hasVoucher || orderIssuecount">Actions</PageSectionHeading>
                <router-link :to="{ name: 'PendingOrders'}" class="quick-link action-link col-md-6 mb-4" v-if="preferences.showActionDraftOrders">
                    <div class="icon-column">
                        <div class="action-icon">
                            {{ draftOrderCount }}
                        </div>
                    </div>
                    <div class="text">
                        <div><strong>Draft Orders</strong></div>
                        <div v-if="draftOrderCount === 0" class="text-description">
                            You have no draft orders to review
                        </div>
                        <div v-else class="text-description">
                            You have {{ draftOrderCount }} draft order<span v-if="draftOrderCount > 1">s</span>,
                            you can review <span v-if="draftOrderCount > 1">them</span><span v-else>it</span> by clicking here.
                        </div>
                    </div>
                    <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
                </router-link>

                <router-link :to="{ name: 'OrdersIssues'}" class="quick-link action-link col-md-6 mb-4" v-if="orderIssuecount">
                    <div class="icon-column">
                        <div class="action-icon issue-title text-danger">
                            {{ orderIssuecount }}
                        </div>
                    </div>
                    <div class="text">
                        <div><strong>Orders With Issues</strong></div>
                        <div class="text-description">
                            You have {{ orderIssuecount }} order<span v-if="orderIssuecount > 1">s</span>,
                            which require your attention.
                        </div>
                    </div>
                    <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
                </router-link>

          <VoucherList @voucher="setHasVoucher" />
        </div>
        <PageSectionHeading>Quick Links</PageSectionHeading>
        <div class="row">
            <router-link :to="{ name: 'CollectionCreate'}" v-if="currentPractice && currentPractice.allowed_collections" class="quick-link col-md-6 mb-4">
                <div class="icon-column">
                    <div class="icon">
                        <i class="bi bi-truck"></i>
                    </div>
                </div>
                <div class="text">
                    <div><strong>Book a collection</strong></div>
                    <div class="text-description">Plan your collections for the week ahead.</div>
                </div>
                <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
            </router-link>
            <router-link v-if="preferences.showCreateButton" :to="{ name: 'OrderCreate'}" class="quick-link col-md-6 mb-4">
                <div class="icon-column">
                    <div class="icon">
                        <i class="bi bi-plus"></i>
                    </div>
                </div>
                <div class="text">
                     <div><strong>Create an order</strong></div>
                    <div>Generate a lab ticket to send to us</div>
                </div>
                <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
            </router-link>
            <router-link v-if="preferences.showReturnLabels" :to="{ name: 'ReturnLabel'}" class="quick-link col-md-6 mb-4">
                <div class="icon-column">
                    <div class="icon">
                        <i class="bi bi-box"></i>
                    </div>
                </div>
                <div class="text">
                    <div><strong>Freepost labels</strong></div>
                    <div class="text-description">Create a freepost label to send your orders to us</div>
                </div>
                <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
            </router-link>
            <router-link :to="{ name: 'Help'}" class="quick-link col-md-6 mb-4">
                <div class="icon-column">
                    <div class="icon">
                        <i class="bi bi-play-btn"></i>
                    </div>
                </div>
                <div class="text">
                    <div><strong>Tutorials</strong></div>
                    <div class="text-description">Need help using any aspect of Swift Connect? Find the answers here</div>
                </div>
                <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
            </router-link>
            <a href="https://calendly.com/connect-kupi/30min" target="_blank" class="quick-link col-md-6 mb-4">
                <div class="icon-column">
                    <div class="icon">
                        <i class="bi bi-question"></i>
                    </div>
                </div>
                <div class="text">
                    <div><strong>Scanner Support</strong></div>
                    <div class="text-description">Currently only available for selected trial participants</div>
                </div>
                <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
            </a>
        </div>
    </PageSection>
</template>

<style scoped lang="scss">
    .action
    {
        padding-bottom: 20px;

        .action-link
        {
            display: flex;
            align-items: center;
        }
    
        .action-icon
        {
            aspect-ratio: 1/1;
            line-height: 0;
            font-size: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            width: 70px;
        }        
    }

    .quick-link
    {
        text-decoration: none;
        display: flex;

        .icon-column
        {
            display: flex;
            flex-direction: column;
            margin-right: $spacer;
        }

        .icon
        {
            background: $swift-lighter-grey;
            border-radius: 10px;
            aspect-ratio: 1/1;
            font-size: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
        }

        .text
        {
            flex-grow: 1;
            margin-right: $spacer;
        }

        .text-description
        {
            line-height: 1.3em;
        }

        .arrow
        {
            img
            {
                width: 30px;
                margin-top: 10px;
            }            
        }

        &:hover
        {
            .icon
            {
                background: $swift-light-grey;
                transition: 0.5s;
            }
        }
    }
</style>

<script>
    import api from "@/services/api";
    import SwiftFlexAdvert from '@/components/SwiftFlexAdvert.vue';
    import PageSection from '@/components/PageSection.vue';
    import PageSectionHeading from '@/components/PageSectionHeading.vue';
    import VoucherList from "@/components/VoucherList.vue";

    export default {
        components: {
            VoucherList,
            PageSection,
            PageSectionHeading,
            SwiftFlexAdvert,
        },
        data() {
            return {
                draftOrderCount: null,
                hasVoucher: false,
                orderIssuecount: 0,
                preferences: {
                    showCreateButton: null,
                    showActionDraftOrders: false,
                    showReturnLabels: false,
                },
            }
        },
        methods: {
            setHasVoucher(count) {
                this.hasVoucher = count ? true : false;
            },
            fetchDraftOrderCount: function() {
                api
                .get('/api/v1/temp-orders/draft/count', {
                    params: {
                        practiceId: this.$store.state.currentPracticeId,
                    }
                })
                .then((response) => {
                    this.draftOrderCount = response.data;
                })
                .catch(() => {
                    console.log('Retrieving draft order count failed');
                });
            },
            fetchPracticePrefs: function () {
                api
                .get('/api/v1/practices/' + this.$store.state.currentPracticeId + '/preferences', {
                })
                .then((response) => {
                    this.preferences = {
                        showCreateButton: response.data.data.show_create_order,
                        showActionDraftOrders: response.data.data.is_holding_scanner_order,
                        showReturnLabels: response.data.data.is_allowed_return_labels
                    };
                })
                .catch((reason) => {
                    console.log('Catch practice preferences error');
                    console.log(reason);
                });
            },
            fetchOrdersWithIssuesCount: function() {
                api
                .get('/api/v1/getNumberOrdersWithIssues', {
                    params: {
                        practiceId: this.$store.state.currentPracticeId,
                    }
                }).then((response) => {
                    this.orderIssuecount = response.data.order_count;
                })
            },
        },
        computed: {
            currentPractice () {
                return this.$store.state.currentPractice;
            },
            newUser () {
                return this.$store.state.user.login_count <= 2;
            },
            hasWatchedVideo () {
                return this.$store.state.watchedTutorialVideo;
            },
        },
        mounted() {
            this.fetchDraftOrderCount();
            this.fetchPracticePrefs();
            this.fetchOrdersWithIssuesCount();
        }
    }

</script>
<!-- temp-orders/draft/count -->