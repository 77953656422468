<template>
    <ul class="nav flex-column">
        <li :class="{ 'nav-item-current': ['Dashboard'].includes(this.$route.name) }">
            <router-link :to="{ name: 'Dashboard' }" class="nav-link">
                <i class="bi bi-speedometer2 pe-2"></i> Dashboard
            </router-link>
        </li>
        <li :class="{ 'nav-item-current': ['Orders', 'PendingOrders', 'Order', 'OrderCreate', 'OrderReturn', 'OrderCreateSend', 'Consignment', 'OrderUpdate', 'OrdersIssues'].includes(this.$route.name) }">
            <router-link :to="{ name: 'Orders' }" class="nav-link">
                <i class="bi bi-inbox pe-2"></i> Orders
            </router-link>
            <ul class="nav flex-column" v-if="['Orders', 'PendingOrders', 'OrderCreateSend', 'OrderUpdate', 'OrderCreate', 'OrdersIssues'].includes(this.$route.name)">
                <li class="sub-nav-item" :class="{ 'nav-item-current': ['Orders'].includes(this.$route.name) }">
                    <router-link :to="{ name: 'Orders' }" class="nav-link">
                        Accepted
                    </router-link>
                </li>
                <li class="sub-nav-item" :class="{ 'nav-item-current': ['PendingOrders'].includes(this.$route.name) }">
                    <router-link :to="{ name: 'PendingOrders' }" class="nav-link">
                        Requested
                    </router-link>
                </li>
                <li class="sub-nav-item" :class="{ 'nav-item-current': ['OrdersIssues'].includes(this.$route.name) }">
                    <router-link :to="{ name: 'OrdersIssues' }" class="nav-link">
                        Issues
                    </router-link>
                </li>
            </ul>
        </li>
        <li v-if="currentPractice && currentPractice.allowed_collections" :class="{ 'nav-item-current': ['Collections', 'CollectionCreate', 'CollectionsHistoric', 'CollectionCreate'].includes(this.$route.name) }">
            <router-link :to="{ name: 'Collections' }" class="nav-link">
                <i class="bi bi-truck pe-2"></i> Collections
            </router-link>
            <ul class="nav flex-column" v-if="['Collections', 'CollectionsHistoric', 'CollectionCreate'].includes(this.$route.name)">
                <li class="sub-nav-item" :class="{ 'nav-item-current': ['Collections'].includes(this.$route.name) && (this.$route.params.state === undefined ||  this.$route.params.state == 'future') }">
                    <router-link :to="{ name: 'Collections', params: { state: 'future' } }" class="nav-link">
                        Upcoming
                    </router-link>
                </li>
                <li class="sub-nav-item" :class="{ 'nav-item-current': ['CollectionsHistoric'].includes(this.$route.name) }">
                    <router-link :to="{ name: 'CollectionsHistoric' }" class="nav-link">
                        Historical
                    </router-link>
                </li>
            </ul>
        </li>
        <li :class="{ 'nav-item-current': ['Billing', 'Accounts', 'Account', 'Transaction', 'Statements', 'SwiftPlus', 'SwiftPlusStatement'].includes(this.$route.name) }" v-if="$filters.canAccess($accessLevel.sensitive)">
            <router-link :to="{ name: 'Billing' }" class="nav-link">
                <i class="bi bi-currency-pound pe-2"></i> Billing
            </router-link>
            <ul class="nav flex-column" v-if="['Billing', 'Accounts', 'Account', 'Transaction', 'Statements', 'SwiftPlus', 'SwiftPlusStatement'].includes(this.$route.name)">
                <li class="sub-nav-item" :class="{ 'nav-item-current': ['Accounts', 'Account', 'Transaction', 'Statements'].includes(this.$route.name) }">
                    <router-link :to="{ name: 'Accounts' }" class="nav-link">
                        Accounts
                    </router-link>
                </li>
                <li class="sub-nav-item" :class="{ 'nav-item-current': ['SwiftPlus', 'SwiftPlusStatement'].includes(this.$route.name) }">
                    <router-link :to="{ name: 'SwiftPlus' }" class="nav-link">
                        SwiftPlus
                    </router-link>
                </li>
            </ul>
        </li>
        <li :class="{ 'nav-item-current': ['Reports', 'Invoices', 'Patient Statements'].includes(this.$route.name) }" v-if="$filters.canAccess($accessLevel.sensitive)">
            <router-link :to="{ name: 'Reports' }" class="nav-link">
                <i class="bi bi-files pe-2"></i> Reports
            </router-link>
        </li>
        <li :class="{ 'nav-item-current': ['Users', 'Invitations', 'User', 'Invitation', 'Invite'].includes(this.$route.name) }" v-if="$filters.canAccess($accessLevel.full)">
            <router-link :to="{ name: 'Users' }" class="nav-link">
                <i class="bi bi-person pe-2"></i> Users
            </router-link>
        </li>
      <li :class="{ 'nav-item-current': ['Downloads'].includes(this.$route.name) }">
        <router-link :to="{ name: 'Downloads' }" class="nav-link">
          <i class="bi bi-cloud-download pe-2"></i> Downloads
        </router-link>
      </li>
      <li v-if="currentPractice && currentPractice.access_level === 3" :class="{ 'nav-item-current': ['Settings','SettingsPracticeDetails','SettingsPracticePreferences'].includes(this.$route.name) }">
        <router-link :to="{ name: 'Settings' }" class="nav-link">
          <i class="bi bi-gear pe-2"></i> Settings
        </router-link>
        <ul class="nav flex-column" v-if="['Settings','SettingsPracticeDetails','SettingsPracticePreferences'].includes(this.$route.name)">
          <li class="sub-nav-item" :class="{ 'nav-item-current': ['SettingsPracticeDetails'].includes(this.$route.name) }">
            <router-link :to="{ name: 'SettingsPracticeDetails' }" class="nav-link">
              Address
            </router-link>
          </li>
          <li class="sub-nav-item" :class="{ 'nav-item-current': ['SettingsPracticePreferences'].includes(this.$route.name) }">
            <router-link :to="{ name: 'SettingsPracticePreferences' }" class="nav-link">
              Preferences
            </router-link>
          </li>
        </ul>
      </li>
        <li :class="{ 'nav-item-current': ['Help'].includes(this.$route.name) }">
            <router-link :to="{ name: 'Help' }" class="nav-link">
                <i class="bi bi-question-circle pe-2"></i> Help
            </router-link>
        </li>
    </ul>
</template>

<style scoped lang="scss">
    .sub-nav-item
    {
        font-size: 0.8em;

        a
        {
            padding: 3px 0px 3px 60px;
            color: $swift-mid-grey;
            font-weight: bold;
        }
    }

    .nav-item-current
    {
        > a
        {
            color: darken($swift-fresh-green, 10);;
            font-weight: bold;
        }
    }
</style>

<script>
    export default {
        computed: {
            currentPractice () {
                return this.$store.state.currentPractice;
            },
        },
    }
</script>
