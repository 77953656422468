<template>
    <div class="carousel mb-5">
        <div class="slides">
            <div id="slide-0" class="slide current-slide" ref="slide0">
                <div class="content">
                    <div class="logo"><img src="../assets/images/swift-flex-white.png" /></div>
                    <div class="info">
                        <div class="text">With a commitment of just two SwiftFlex cases per month you can get a Trios 3 POD for FREE through our SwiftPlus sponsorship.</div>
                    </div>
                </div>
            </div>
            <div id="slide-1" class="slide" ref="slide1">
                <div class="content">
                    <div class="pricing-heading">
                        <div class="logo"><img src="../assets/images/swift-flex-white.png" /></div>
                        <div class="pricing-conditions">
                            <div>* Single arch cases will be charged at 85% of dual arch price</div>
                            <div>* Discounted rates on quantity to be discussed</div>
                        </div>
                    </div>
                    <div class="pricing-columns">
                        <div class="pricing-column">
                            <div class="pricing-column-title">Package 01</div>
                            <div class="pricing-column-content">
                                <div>1-10 Sets</div>
                                <hr />
                                <div>&pound;225 - &pound;495</div>
                            </div>
                        </div>
                        <div class="pricing-column">
                            <div class="pricing-column-title pricing-column-title-pink">Package 02</div>
                            <div class="pricing-column-content">
                                <div>11-20 Sets</div>
                                <hr />
                                <div>&pound;850</div>
                            </div>
                        </div>
                        <div class="pricing-column">
                            <div class="pricing-column-title pricing-column-title-green">Package 03</div>
                            <div class="pricing-column-content">
                                <div>21-30 Sets</div>
                                <hr />
                                <div>&pound;1,125</div>
                            </div>
                        </div>
                        <div class="pricing-column">
                            <div class="pricing-column-title pricing-column-title-yellow">Package 04</div>
                            <div class="pricing-column-content">
                                <div>Unlimited</div>
                                <hr />
                                <div>&pound;1,250</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="slide-2" class="slide" ref="slide2">
                <div class="content">
                    <div class="logo"><img src="../assets/images/swift-flex-white.png" /></div>
                    <div class="info">
                        <div class="text">We're more than just an affordable aligner manufacturer, we're your committed partner, positioning dental experience at the forefront of everything we do.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-info">Call the SwiftFlex team on 01204 323 329 for more information or to book a consultation</div>
    </div>
</template>

<script>
    export default {
        mounted() {
            setInterval(function(){
                this.nextSlide();
            }.bind(this), 7000);
        },
        data() {
            return {
                currentSlide: 0,
            }
        },
        methods: {
            nextSlide: function()
            {
                var slides = [
                    this.$refs.slide0,
                    this.$refs.slide1,
                    this.$refs.slide2,
                ];

                console.log('next');
                this.currentSlide++;
                if(this.currentSlide >= slides.length)
                {
                    this.currentSlide = 0;
                }

                slides.forEach((slide, index) => {
                    if (slide == null) {
                        return;
                    }
                    if(index != this.currentSlide)
                    {
                        slide.className = 'slide';
                    }
                    else
                    {
                        slide.className = 'slide current-slide';
                    }
                });
            },
        },
    }
</script>


<style scoped lang="scss">

    .carousel
    {
        display: none;
        color: $white;
        background: rgba(53, 57, 67, 1);
        border-radius: 10px;

        @include media-breakpoint-up(xl)
        {
            display: block;
        }
    }

    .slides
    {
        display: grid;
        background: rgb(64,75,85);
        background: linear-gradient(180deg, rgba(53, 57, 67, 1) 0%, rgba(81, 125, 140, 1) 100%);
        border-radius: 10px;
    }

    .more-info
    {
        padding: 10px 20px;
        text-align: center;

        @include media-breakpoint-up(xxl)
        {
            font-size: 1.2em;
        }
    }

    .slide
    {
        grid-area: 1 / 1 / 2 / 2;
        opacity: 0;
        padding: 30px 40px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        z-index: 1;
        transition: opacity 1s;
        height: 320px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        padding-left: 355px;

        @include media-breakpoint-up(xxl)
        {
            display: grid;
        }

        .content
        {
            width: 100%;
        }

        .logo
        {
            font-size: 2em;
            line-height: 1;
            
            img
            {
                width: 250px;
            }
        }

        .text
        {
            margin-top: 20px;
            font-size: 1.5em;
            color: $white;
            line-height: 1.1em;

            @include media-breakpoint-up(xxl)
            {
                font-size: 1.7em;
            }
        }
    }

    .pricing-heading
    {
        display: flex;
        margin-bottom: 20px;

        .pricing-conditions
        {
            flex-grow: 1;
            text-align: right;
            font-size: 0.95em;
        }
    }

    .pricing-columns
    {
        display: flex;
        gap: 15px;
        padding-top: 15px;

        .pricing-column
        {
            position: relative;
            flex-grow: 1;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            padding-top: 25px;
            text-align: center;
            font-size: 1.2em;

            @include media-breakpoint-up(xxl)
            {
                font-size: 1.3em;
            }
        }

        .pricing-column-title
        {
            position: absolute;
            top: -15px;
            left: 7%;
            width: 86%;
            background: $swift-mint-blue;
            color: $swift-not-black;
            border-radius: 20px;
        }

        .pricing-column-title-green
        {
            background: $swift-fresh-green;
        }

        .pricing-column-title-pink
        {
            background: $swift-pink;
        }

        .pricing-column-title-yellow
        {
            background: $swift-yellow;
        }

        .pricing-column-content
        {
            padding: 0px 15px;

            div
            {
                padding: 15px 0px;
            }

            hr
            {
                margin: 0px;
                border-top-color: rgba(255,255,255, 0.5);
            }
        }
    }

    .current-slide
    {
        z-index: 2;
        opacity: 1;
        transition: opacity 1s;
    }

    #slide-0
    {
        background-image: url('../assets/images/swift-flex-advert/laptop.png');
    }

    #slide-1
    {
        padding-left: 40px;
    }

    #slide-2
    {
        background-image: url('../assets/images/swift-flex-advert/aligner.png');
    }
</style>